import crypto from 'crypto';
import { Plugin } from '@nuxt/types';
import { T360ModuleOptions } from './types';

declare module 'vue/types/vue' {
  interface Vue {
    $t360: {
      enabled: boolean;
      baseUrl: string;
      apiKey: string;
      widgetId: string;
      encrypt: (sourceId: string, t360ApiKey: string) => string;
    };
  }
}

declare module '@nuxt/types' {
  interface Context {
    $t360: {
      enabled: boolean;
      baseUrl: string;
      apiKey: string;
      widgetId: string;
      encrypt: (sourceId: string, t360ApiKey: string) => string;
    };
  }
}

const t360Plugin: Plugin = (context, inject) => {
  const options = JSON.parse('{"enabled":true,"baseUrl":"https://bbrdevwb.preference360.io","apiKey":"a9d691bf09e44bc6aa0c2532bb18f6a0","widgetId":"83532383726D42BA88D766D7AFD48632"}') as T360ModuleOptions;
  const {
    enabled,
    baseUrl,
    apiKey,
    widgetId,
  } = options;

  const encrypt = (sourceId: string, t360ApiKey: string): string => {
    // Ensure t360ApiKey is at least 32 characters long
    if (t360ApiKey.length < 32) {
      throw new Error('t360ApiKey must be at least 32 characters long');
    }

    // Create the secret key and initialization vector
    const secretKey = t360ApiKey.substring(0, 16); // First 16 chars
    const iv = Buffer.from(t360ApiKey.substring(16, 32), 'utf-8'); // Next 16 chars

    const cipher = crypto.createCipheriv('aes-128-cbc', Buffer.from(secretKey, 'utf-8'), iv);
    let encrypted = cipher.update(sourceId, 'utf-8', 'base64');
    encrypted += cipher.final('base64');

    return encrypted;
  };

  const getBaseUrl = (baseUrl: string): string => {
    let url = baseUrl;

    if (url.endsWith('/')) {
      url = url.slice(0, -1)
    }

    return url;
  };

  inject('t360', {
    enabled,
    baseUrl: getBaseUrl(baseUrl),
    apiKey,
    widgetId,
    encrypt,
  });
};

export default t360Plugin;
