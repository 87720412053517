


































import {
  defineComponent,
  useRoute,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import AppHeader from '@theme/components/AppHeader.vue';
import AppHeaderTemp from '@theme/components/temp/AppHeader.vue';
import AppFooter from '~/bbrTheme/components/AppFooter.vue';
import AssistedShopperHeader from '~/modules/assisted-shopper/components/AssistedShopperHeader.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import PreviewMessage from '~/modules/amplience/components/molecules/PreviewMessage.vue';
import ScrollToTop from '~/bbrTheme/components/ScrollToTop.vue'
import QuickAccessModal from '~/modules/azure-b2c-auth/components/header/QuickAccessModal.vue';
import AccountSwitcherModal from '~/modules/azure-b2c-auth/components/header/AccountSwitcherModal.vue';
import { useCellar } from '@cellar-services/composables/useCellar';
import { useCellarUiStore } from '@cellar-services/stores/cellar-ui';
import { usePageStore } from '~/stores/page';
import { useUser } from '~/modules/customer/composables/useUser';

const tempTypes = [
  'ARTICLE_VIEW',
  'ARTICLE_CATEGORY',
  'ARTICLE_LANDING',
  'CONTENT_PAGE',
];

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppHeaderTemp,
    AppFooter,
    AssistedShopperHeader,
    PreviewMessage,
    IconSprite,
    ScrollToTop,
    QuickAccessModal,
    AccountSwitcherModal,
    NotificationGroup: () => import('~/bbrTheme/components/NotificationGroup.vue'),
  },
  transition: 'fade',
  setup() {
    const route = useRoute();
    const { routeData } = usePageStore();
    const pageType = computed(() => routeData?.type);
    const isCellarLayout = computed(() =>
      route.value.path.startsWith('/cellar') || route.value.path.startsWith('/bbx')
    );

    const isTempLayout = computed(() => tempTypes.includes(pageType.value));

    const { load: loadCustomer } = useUser();
    const { refreshCellar } = useCellar();
    const { setIsOpenedFromHistory } = useCellarUiStore();

    const handlePopstate = (event: PopStateEvent) => {
      setIsOpenedFromHistory(true);
    };

    onMounted(async () => {
      window.addEventListener('popstate', handlePopstate);

      await loadCustomer();

      refreshCellar();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('popstate', handlePopstate);
    });

    watch(route, () => {
      if (process.client) {
        document.body.setAttribute('tabindex', '-1');
        document.body.focus();
        document.body.removeAttribute('tabindex');
        setIsOpenedFromHistory(false);
      }
    });

    return {
      route,
      isTempLayout,
      isCellarLayout,
    };
  }
});
