




















import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useAuth } from '~/modules/azure-b2c-auth/composables/useAuth';
import useUiState from '~/composables/useUiState';
import SvgImage from '~/components/General/SvgImage.vue';
import { isDualRunning } from '~/bbrTheme/helpers/dualRunning';

export default defineComponent({
  name: 'ActionItemAccount',
  components: {
    SvgImage,
  },
  setup() {
    const { $config: { isAzureEnabled }, app } = useContext();
    const { login } = useAuth();
    const { isAuthenticated, redirectToLogin } = useUser();
    const { openQuickAccessModal } = useUiState();

    const toggle = (): void => {
      if (isAuthenticated.value) {
        openQuickAccessModal();
      } else {
        if (isAzureEnabled && !isDualRunning(app.$cookies)) {
          login();
        } else {
          redirectToLogin();
        }
      }
    };

    return {
      toggle,
      isAuthenticated,
    };
  },
});
