







import {defineComponent, useContext, useFetch} from '@nuxtjs/composition-api';
import { useContent } from '~/modules/amplience/composables/useContent';

export default defineComponent({
  name: 'AppFooter',
  props: {
    deliveryKey: {
      type: String,
      default: 'global-footer',
    },
  },
  setup(props) {
    const { search, data } = useContent();
    const {
      $amplience: {
        footerDeliveryKey,
        globalFooterDeliveryKey,
      }
    } = useContext();

    useFetch(async () => {
      const deliveryKey = props.deliveryKey === 'footer' ? footerDeliveryKey : globalFooterDeliveryKey;

      await search(deliveryKey || props.deliveryKey, 'url');
    });

    return {
      data,
    };
  },
});
