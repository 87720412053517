













import { defineComponent, useContext, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '@amplience/composables/useContent';
import { ImageInterface, NavigationInterface } from '@amplience/types';
import ActionItemGroup from '@theme/components/global-header/ActionItemGroup.vue';
import GlobalHeader from '@amplience/components/content-type/GlobalHeader.vue';

interface GlobalHeaderResult {
  search: (id: string, type: string) => Promise<void>;
  data: {
    navigation: NavigationInterface[];
    logo?: ImageInterface;
  };
}

export default defineComponent({
  name: 'AppHeader',
  components: {
    ActionItemGroup,
    GlobalHeader,
  },
  setup() {
    const { search, data } = useContent() as GlobalHeaderResult;
    const {
      $amplience: {
        globalHeaderDeliveryKey
      }
    } = useContext();

    useFetch(async () => {
      await search(globalHeaderDeliveryKey, 'url');
    });

    return {
      data,
    };
  },
});
