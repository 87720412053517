






import { defineComponent, useContext, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/modules/amplience/composables/useContent';
import AmplienceHeader from '@amplience/components/content-type/AmplienceHeader.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    AmplienceHeader,
  },
  setup() {
    const { search, data } = useContent();
    const {
      $amplience: {
        headerDeliveryKey
      }
    } = useContext();

    useFetch(async () => {
      await search(headerDeliveryKey, 'url');
    });

    return {
      data,
    };
  },
});
