import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b775260 = () => interopDefault(import('../integrations/cellar-services/src/pages/MyBBX.vue' /* webpackChunkName: "" */))
const _8bf5ace2 = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/ManageAlerts.vue' /* webpackChunkName: "" */))
const _7760d366 = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/BidsPlaced.vue' /* webpackChunkName: "" */))
const _35acfa6c = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/BidsReceived.vue' /* webpackChunkName: "" */))
const _38aa37a2 = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/Listings.vue' /* webpackChunkName: "" */))
const _55738f1e = () => interopDefault(import('../integrations/cellar-services/src/pages/bbx/Dashboard.vue' /* webpackChunkName: "" */))
const _7ddb9963 = () => interopDefault(import('../modules/checkout/pages/CartGrouped.vue' /* webpackChunkName: "" */))
const _1582489b = () => interopDefault(import('../integrations/cellar-services/src/pages/MyCellar.vue' /* webpackChunkName: "" */))
const _bbf09826 = () => interopDefault(import('../integrations/cellar-services/src/pages/cellar/CellarItems.vue' /* webpackChunkName: "" */))
const _27625ddc = () => interopDefault(import('../integrations/cellar-services/src/pages/cellar/CellarOverview.vue' /* webpackChunkName: "" */))
const _23462fb5 = () => interopDefault(import('../integrations/cellar-services/src/pages/cellar/CellarValuations.vue' /* webpackChunkName: "" */))
const _e503b35e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _27a262c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _ea43beb0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _4d358908 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7a19f1ba = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _90e710f8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _6feb273d = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _334aaf70 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _6d79972f = () => interopDefault(import('../bbrTheme/modules/customer/pages/CommunicationPreferences.vue' /* webpackChunkName: "" */))
const _5f3ac4ec = () => interopDefault(import('../bbrTheme/modules/customer/pages/Interests.vue' /* webpackChunkName: "" */))
const _d420c842 = () => interopDefault(import('../bbrTheme/modules/customer/pages/ManagePayments.vue' /* webpackChunkName: "" */))
const _0f6fbf8e = () => interopDefault(import('../bbrTheme/modules/customer/pages/MyAccountDetails.vue' /* webpackChunkName: "" */))
const _743d3078 = () => interopDefault(import('../integrations/hybris/src/pages/AddressBook/HybrisAddressesDetails.vue' /* webpackChunkName: "" */))
const _24645cae = () => interopDefault(import('../bbrTheme/modules/customer/pages/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _34025bdc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _614cbb1e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _47206851 = () => interopDefault(import('../bbrTheme/modules/customer/pages/MyStoreCredit.vue' /* webpackChunkName: "" */))
const _0a594199 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _7b112f44 = () => interopDefault(import('../integrations/hybris/src/pages/AddressBook/HybrisAddressNew.vue' /* webpackChunkName: "" */))
const _20427cea = () => interopDefault(import('../bbrTheme/modules/customer/pages/MyProfile/ChangePassword.vue' /* webpackChunkName: "" */))
const _310ce5be = () => interopDefault(import('../bbrTheme/modules/customer/pages/MyProfile/EditEmailAddress.vue' /* webpackChunkName: "" */))
const _0602e873 = () => interopDefault(import('../bbrTheme/modules/customer/pages/MyProfile/EditPersonalInformation.vue' /* webpackChunkName: "" */))
const _11cc6bd4 = () => interopDefault(import('../integrations/hybris/src/pages/AddressBook/HybrisAddressEdit.vue' /* webpackChunkName: "" */))
const _27318dd6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _1f9edd2c = () => interopDefault(import('../bbrTheme/pages/grapes.vue' /* webpackChunkName: "" */))
const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _2f507131 = () => interopDefault(import('../modules/cellar-plan/pages/join-amp.vue' /* webpackChunkName: "" */))
const _69a169e0 = () => interopDefault(import('../modules/cellar-plan/pages/join-cp.vue' /* webpackChunkName: "" */))
const _08c10c52 = () => interopDefault(import('../bbrTheme/pages/Login.vue' /* webpackChunkName: "" */))
const _877af60c = () => interopDefault(import('../modules/assisted-shopper/pages/loginAsCustomer.vue' /* webpackChunkName: "" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _5439c6af = () => interopDefault(import('../bbrTheme/pages/producers.vue' /* webpackChunkName: "" */))
const _10ef6716 = () => interopDefault(import('../modules/algolia-search/pages/search.vue' /* webpackChunkName: "" */))
const _41778e4d = () => interopDefault(import('../bbrTheme/pages/styleguide.vue' /* webpackChunkName: "" */))
const _6175f614 = () => interopDefault(import('../modules/amplience/pages/visualisation.vue' /* webpackChunkName: "" */))
const _5b80e5d6 = () => interopDefault(import('../modules/algolia-search/pages/category-demo.vue' /* webpackChunkName: "" */))
const _4b62576d = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/checkout-redirect.vue' /* webpackChunkName: "" */))
const _1ed0f9fc = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/create.vue' /* webpackChunkName: "" */))
const _a1cb5b5a = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/login.vue' /* webpackChunkName: "" */))
const _8e2d3874 = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/login-redirect.vue' /* webpackChunkName: "" */))
const _44dac662 = () => interopDefault(import('../modules/azure-b2c-auth/pages/auth/redirect.vue' /* webpackChunkName: "" */))
const _2549d27c = () => interopDefault(import('../modules/algolia-search/pages/category-listing.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bbx",
    component: _4b775260,
    redirect: "/bbx/overview",
    name: "bbx___default",
    children: [{
      path: "alerts",
      component: _8bf5ace2,
      name: "bbx-alerts___default"
    }, {
      path: "bids-placed",
      component: _7760d366,
      name: "bbx-bids-placed___default"
    }, {
      path: "bids-received",
      component: _35acfa6c,
      name: "bbx-bids-received___default"
    }, {
      path: "listings",
      component: _38aa37a2,
      name: "bbx-listings___default"
    }, {
      path: "overview",
      component: _55738f1e,
      name: "bbx-overview___default"
    }]
  }, {
    path: "/cart",
    component: _7ddb9963,
    name: "cart___default"
  }, {
    path: "/cellar",
    component: _1582489b,
    redirect: "/cellar/overview",
    name: "cellar___default",
    children: [{
      path: "items",
      component: _bbf09826,
      name: "cellar-items___default"
    }, {
      path: "overview",
      component: _27625ddc,
      name: "cellar-overview___default"
    }, {
      path: "valuations",
      component: _23462fb5,
      name: "cellar-valuations___default"
    }]
  }, {
    path: "/checkout",
    component: _e503b35e,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___default"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___default"
    }, {
      path: "orderConfirmation/:orderno",
      component: _7a19f1ba,
      name: "orderConfirmation___default"
    }]
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms___default"
  }, {
    path: "/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "communication-preferences",
      component: _6d79972f,
      meta: {"titleLabel":"Communication preferences"},
      alias: "/customer/communication-preferences",
      name: "customer-communication-preferences___default"
    }, {
      path: "interests",
      component: _5f3ac4ec,
      meta: {"titleLabel":"Interests"},
      name: "customer-interests___default"
    }, {
      path: "manage-payments",
      component: _d420c842,
      meta: {"titleLabel":"Manage payments"},
      name: "customer-manage-payments___default"
    }, {
      path: "my-account",
      component: _0f6fbf8e,
      meta: {"titleLabel":"My account"},
      name: "customer-my-account___default"
    }, {
      path: "my-address-book",
      component: _743d3078,
      meta: {"titleLabel":"Address book","parentPage":"my-address-book"},
      name: "customer-my-address-book___default"
    }, {
      path: "my-profile",
      component: _24645cae,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"Wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Orders, payments & statements"},
      name: "customer-order-history___default"
    }, {
      path: "/my-store-credit",
      component: _47206851,
      meta: {"titleLabel":"My Store Credit"},
      alias: "/customer/my-store-credit",
      name: "customer-my-store-credit___default"
    }, {
      path: "/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "my-address-book/create",
      component: _7b112f44,
      meta: {"titleLabel":"Address book","parentPage":"my-address-book"},
      name: "customer-my-address-book-new___default"
    }, {
      path: "my-profile/change-password",
      component: _20427cea,
      meta: {"titleLabel":"Change password","parentPage":"my-profile"},
      name: "customer-change-password___default"
    }, {
      path: "my-profile/edit-email",
      component: _310ce5be,
      meta: {"titleLabel":"Edit email address","parentPage":"my-profile"},
      name: "customer-edit-email___default"
    }, {
      path: "my-profile/edit-personal-information",
      component: _0602e873,
      meta: {"titleLabel":"Edit personal information","parentPage":"my-profile"},
      name: "customer-edit-personal-info___default"
    }, {
      path: "my-address-book/edit/:addressId",
      component: _11cc6bd4,
      meta: {"titleLabel":"Address book","parentPage":"my-address-book"},
      props: true,
      name: "customer-my-address-book-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/grapes",
    component: _1f9edd2c,
    name: "grapes___default"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___default"
  }, {
    path: "/join-account-management-plan",
    component: _2f507131,
    name: "join-account-management-plan___default"
  }, {
    path: "/join-cellar-plan",
    component: _69a169e0,
    name: "join-cellar-plan___default"
  }, {
    path: "/login",
    component: _08c10c52,
    name: "login___default"
  }, {
    path: "/login-as-customer",
    component: _877af60c,
    name: "login-as-customer___default"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___default"
  }, {
    path: "/preview",
    component: _115548ca,
    name: "home___preview"
  }, {
    path: "/producers",
    component: _5439c6af,
    name: "producers___default"
  }, {
    path: "/search",
    component: _10ef6716,
    name: "algolia-search___default"
  }, {
    path: "/styleguide",
    component: _41778e4d,
    name: "styleguide___default"
  }, {
    path: "/visualization",
    component: _6175f614,
    name: "visualization___default"
  }, {
    path: "/algolia-category/accessories",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Accessories"},
    name: "algolia-category-accessories___default"
  }, {
    path: "/algolia-category/assortments",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Assortment Mixed Cases"},
    name: "algolia-category-assortments___default"
  }, {
    path: "/algolia-category/books",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Books"},
    name: "algolia-category-books___default"
  }, {
    path: "/algolia-category/bundle-mixed-case",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Bundle Mixed Case"},
    name: "algolia-category-bundle-mixed-case___default"
  }, {
    path: "/algolia-category/events",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Ticketed Events"},
    name: "algolia-category-events___default"
  }, {
    path: "/algolia-category/glassware",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Glassware"},
    name: "algolia-category-glassware___default"
  }, {
    path: "/algolia-category/other",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Other Drinks"},
    name: "algolia-category-other___default"
  }, {
    path: "/algolia-category/spirits",
    component: _5b80e5d6,
    meta: {"categoryUid":"MTI2MTE1","familyType":"Spirits"},
    name: "algolia-category-spirits___default"
  }, {
    path: "/algolia-category/wines",
    component: _5b80e5d6,
    meta: {"categoryUid":"NzkxNDE=","familyType":"Wines"},
    name: "algolia-category-wines___default"
  }, {
    path: "/auth/checkout-redirect",
    component: _4b62576d,
    name: "azure-auth-checkout-redirect___default"
  }, {
    path: "/auth/create",
    component: _1ed0f9fc,
    name: "azure-auth-create___default"
  }, {
    path: "/auth/login",
    component: _a1cb5b5a,
    name: "azure-auth-login___default"
  }, {
    path: "/auth/login-redirect",
    component: _8e2d3874,
    name: "azure-auth-login-redirect___default"
  }, {
    path: "/auth/redirect",
    component: _44dac662,
    name: "azure-auth-redirect___default"
  }, {
    path: "/preview/bbx",
    component: _4b775260,
    redirect: "/bbx/overview",
    name: "bbx___preview",
    children: [{
      path: "alerts",
      component: _8bf5ace2,
      name: "bbx-alerts___preview"
    }, {
      path: "bids-placed",
      component: _7760d366,
      name: "bbx-bids-placed___preview"
    }, {
      path: "bids-received",
      component: _35acfa6c,
      name: "bbx-bids-received___preview"
    }, {
      path: "listings",
      component: _38aa37a2,
      name: "bbx-listings___preview"
    }, {
      path: "overview",
      component: _55738f1e,
      name: "bbx-overview___preview"
    }]
  }, {
    path: "/preview/cart",
    component: _7ddb9963,
    name: "cart___preview"
  }, {
    path: "/preview/cellar",
    component: _1582489b,
    redirect: "/cellar/overview",
    name: "cellar___preview",
    children: [{
      path: "items",
      component: _bbf09826,
      name: "cellar-items___preview"
    }, {
      path: "overview",
      component: _27625ddc,
      name: "cellar-overview___preview"
    }, {
      path: "valuations",
      component: _23462fb5,
      name: "cellar-valuations___preview"
    }]
  }, {
    path: "/preview/checkout",
    component: _e503b35e,
    name: "checkout___preview",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___preview"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment___preview"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___preview"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you___preview"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___preview"
    }, {
      path: "orderConfirmation/:orderno",
      component: _7a19f1ba,
      name: "orderConfirmation___preview"
    }]
  }, {
    path: "/preview/Cms",
    component: _6feb273d,
    name: "Cms___preview"
  }, {
    path: "/preview/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer___preview",
    children: [{
      path: "communication-preferences",
      component: _6d79972f,
      meta: {"titleLabel":"Communication preferences"},
      alias: "/customer/communication-preferences",
      name: "customer-communication-preferences___preview"
    }, {
      path: "interests",
      component: _5f3ac4ec,
      meta: {"titleLabel":"Interests"},
      name: "customer-interests___preview"
    }, {
      path: "manage-payments",
      component: _d420c842,
      meta: {"titleLabel":"Manage payments"},
      name: "customer-manage-payments___preview"
    }, {
      path: "my-account",
      component: _0f6fbf8e,
      meta: {"titleLabel":"My account"},
      name: "customer-my-account___preview"
    }, {
      path: "my-address-book",
      component: _743d3078,
      meta: {"titleLabel":"Address book","parentPage":"my-address-book"},
      name: "customer-my-address-book___preview"
    }, {
      path: "my-profile",
      component: _24645cae,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___preview"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"Wishlist"},
      name: "customer-my-wishlist___preview"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Orders, payments & statements"},
      name: "customer-order-history___preview"
    }, {
      path: "my-address-book/create",
      component: _7b112f44,
      meta: {"titleLabel":"Address book","parentPage":"my-address-book"},
      name: "customer-my-address-book-new___preview"
    }, {
      path: "my-profile/change-password",
      component: _20427cea,
      meta: {"titleLabel":"Change password","parentPage":"my-profile"},
      name: "customer-change-password___preview"
    }, {
      path: "my-profile/edit-email",
      component: _310ce5be,
      meta: {"titleLabel":"Edit email address","parentPage":"my-profile"},
      name: "customer-edit-email___preview"
    }, {
      path: "my-profile/edit-personal-information",
      component: _0602e873,
      meta: {"titleLabel":"Edit personal information","parentPage":"my-profile"},
      name: "customer-edit-personal-info___preview"
    }, {
      path: "/preview/my-store-credit",
      component: _47206851,
      meta: {"titleLabel":"My Store Credit"},
      alias: "/customer/my-store-credit",
      name: "customer-my-store-credit___preview"
    }, {
      path: "/preview/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password___preview"
    }, {
      path: "my-address-book/edit/:addressId",
      component: _11cc6bd4,
      meta: {"titleLabel":"Address book","parentPage":"my-address-book"},
      props: true,
      name: "customer-my-address-book-edit___preview"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___preview"
    }]
  }, {
    path: "/preview/grapes",
    component: _1f9edd2c,
    name: "grapes___preview"
  }, {
    path: "/preview/Home",
    component: _115548ca,
    name: "Home___preview"
  }, {
    path: "/preview/join-account-management-plan",
    component: _2f507131,
    name: "join-account-management-plan___preview"
  }, {
    path: "/preview/join-cellar-plan",
    component: _69a169e0,
    name: "join-cellar-plan___preview"
  }, {
    path: "/preview/login",
    component: _08c10c52,
    name: "login___preview"
  }, {
    path: "/preview/login-as-customer",
    component: _877af60c,
    name: "login-as-customer___preview"
  }, {
    path: "/preview/Page",
    component: _478a714b,
    name: "Page___preview"
  }, {
    path: "/preview/producers",
    component: _5439c6af,
    name: "producers___preview"
  }, {
    path: "/preview/search",
    component: _10ef6716,
    name: "algolia-search___preview"
  }, {
    path: "/preview/styleguide",
    component: _41778e4d,
    name: "styleguide___preview"
  }, {
    path: "/preview/visualization",
    component: _6175f614,
    name: "visualization___preview"
  }, {
    path: "/preview/algolia-category/accessories",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Accessories"},
    name: "algolia-category-accessories___preview"
  }, {
    path: "/preview/algolia-category/assortments",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Assortment Mixed Cases"},
    name: "algolia-category-assortments___preview"
  }, {
    path: "/preview/algolia-category/books",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Books"},
    name: "algolia-category-books___preview"
  }, {
    path: "/preview/algolia-category/bundle-mixed-case",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Bundle Mixed Case"},
    name: "algolia-category-bundle-mixed-case___preview"
  }, {
    path: "/preview/algolia-category/events",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Ticketed Events"},
    name: "algolia-category-events___preview"
  }, {
    path: "/preview/algolia-category/glassware",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Glassware"},
    name: "algolia-category-glassware___preview"
  }, {
    path: "/preview/algolia-category/other",
    component: _5b80e5d6,
    meta: {"categoryUid":"","familyType":"Other Drinks"},
    name: "algolia-category-other___preview"
  }, {
    path: "/preview/algolia-category/spirits",
    component: _5b80e5d6,
    meta: {"categoryUid":"MTI2MTE1","familyType":"Spirits"},
    name: "algolia-category-spirits___preview"
  }, {
    path: "/preview/algolia-category/wines",
    component: _5b80e5d6,
    meta: {"categoryUid":"NzkxNDE=","familyType":"Wines"},
    name: "algolia-category-wines___preview"
  }, {
    path: "/preview/auth/checkout-redirect",
    component: _4b62576d,
    name: "azure-auth-checkout-redirect___preview"
  }, {
    path: "/preview/auth/create",
    component: _1ed0f9fc,
    name: "azure-auth-create___preview"
  }, {
    path: "/preview/auth/login",
    component: _a1cb5b5a,
    name: "azure-auth-login___preview"
  }, {
    path: "/preview/auth/login-redirect",
    component: _8e2d3874,
    name: "azure-auth-login-redirect___preview"
  }, {
    path: "/preview/auth/redirect",
    component: _44dac662,
    name: "azure-auth-redirect___preview"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___default"
  }, {
    path: "/preview/algolia-category/:slug",
    component: _5b80e5d6,
    meta: {"categoryUid":"NzkxNDE="},
    name: "algolia-category___preview"
  }, {
    path: "/preview/grapes/:slug",
    component: _2549d27c,
    props: {"categoryType":"grapes","categoryUrl":"grapes"},
    name: "grape___preview"
  }, {
    path: "/preview/producers/:slug",
    component: _2549d27c,
    props: {"categoryType":"producers","categoryUrl":"producers"},
    name: "producer___preview"
  }, {
    path: "/algolia-category/:slug",
    component: _5b80e5d6,
    meta: {"categoryUid":"NzkxNDE="},
    name: "algolia-category___default"
  }, {
    path: "/grapes/:slug",
    component: _2549d27c,
    props: {"categoryType":"grapes","categoryUrl":"grapes"},
    name: "grape___default"
  }, {
    path: "/preview/:slug+",
    component: _478a714b,
    name: "page___preview"
  }, {
    path: "/producers/:slug",
    component: _2549d27c,
    props: {"categoryType":"producers","categoryUrl":"producers"},
    name: "producer___default"
  }, {
    path: "/:slug+",
    component: _478a714b,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
